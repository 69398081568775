:root {
  --body-background: #E9E8E8;
  --body-color: #444;
  --heading-color: black;
  --action-color: black;
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 0 8px;
  font-size: 108%;
  line-height: 1.5;
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: #CB1CED;
}

h1 {
  margin: 1rem 0 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
}

body > header {
  margin: 1rem;
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body > nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body > nav a {
  color: white;
  text-decoration: none;
}

main {
  margin: 2rem auto 4rem;
  max-width: 65rem;
  min-height: calc(100vh - 800px);
  padding: 25px 25px 50px;
  background: white;
  box-shadow: 2px 3px 3px #ddd;
  border-radius: 3px;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
  font-weight: 600;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

input[type=email]{
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #45a049;
}

/* Add a background color and some padding around the form */
.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.photo-gallery {
  text-align: center;
}

.photo-gallery img {
  width: 25%;
}

.overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  overflow: hidden;
  background: black;
  z-index: -1;
}

#main-menu {
  display: block;
  height: 50px;
  width: 100%;
  max-width: 68rem;
  background: black;
  margin: auto;
  z-index: 9;
}

#main-menu ul {
  max-width: 800px;
  width: 100%;
  height: 100%;
  margin: 0px auto;
  padding: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 9;
}

#main-menu li {
  list-style-type: none;
}

#hamburger-input{
  display: none;
}

#hamburger-menu {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  display: none;
  border: none;
  padding: 0px;
  margin: 0px;
  background: linear-gradient(
          to bottom,
          black, black 20%,
          white 20%, white 40%,
          black 40%, black 60%,
          white 60%, white 80%,
          black 80%, black 100%
  );
}

#hamburger-menu #sidebar-menu {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: -250px;
  width: 200px;
  height: 100%;
  background-color: black;
  transition: 0.3s;
  padding: 0px 10px;
  box-sizing: border-box;
}

#hamburger-menu h3 {
  color: white;
  font-size: 2.2rem;
}

#hamburger-menu ul {
  padding-left: 0px;
}

#hamburger-menu li {
  list-style-type: none;
  line-height: 3rem;
}

#hamburger-menu a {
  color: white;
  font-size: 1.3rem;
  text-decoration: none;
}

#hamburger-menu a:hover {
  text-decoration: underline;
}

#hamburger-input:checked + #hamburger-menu #sidebar-menu {
  visibility: visible;
  left: 0;
}
#hamburger-input:checked ~ .overlay{
  visibility: visible;
  opacity: 0.2;
}


@media screen and (max-width: 750px) {
  #main-menu {
    display: none;
  }
  #hamburger-menu {
    display: inline;
  }
}